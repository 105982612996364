@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: green;
  position: absolute;
  animation: loader-animation 3.5s infinite linear;
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg) translateX(13.5rem) scale(0.4);
    opacity: 0;
  }
  100% {
    transform: rotate(-360deg) translateX(13.5rem) scale(1.5);
    opacity: 1;
  }
}

@media (max-width: 1241px) {
  .loader-dot {
    width: 3px;
    height: 3px;
  }
  @keyframes loader-animation {
    0% {
      transform: rotate(0deg) translateX(10rem) scale(0.4);
      opacity: 0;
    }
    100% {
      transform: rotate(-360deg) translateX(10rem) scale(1.5);
      opacity: 1;
    }
  }
}

@media (max-width: 920px) {
  .loader-dot {
    width: 2px;
    height: 2px;
  }
  @keyframes loader-animation {
    0% {
      transform: rotate(0deg) translateX(8.5rem) scale(0.4);
      opacity: 0;
    }
    100% {
      transform: rotate(-360deg) translateX(8.5rem) scale(1.5);
      opacity: 1;
    }
  }
}

@media (max-width: 800px) {
  .loader-dot {
    width: 2px;
    height: 2px;
  }
  @keyframes loader-animation {
    0% {
      transform: rotate(0deg) translateX(6.8rem) scale(0.4);
      opacity: 0;
    }
    100% {
      transform: rotate(-360deg) translateX(6.8rem) scale(1.5);
      opacity: 1;
    }
  }
}

@media (max-width: 350px) {
  .loader-dot {
    width: 2px;
    height: 2px;
  }
  @keyframes loader-animation {
    0% {
      transform: rotate(0deg) translateX(5rem) scale(0.4);
      opacity: 0;
    }
    100% {
      transform: rotate(-360deg) translateX(5rem) scale(1.5);
      opacity: 1;
    }
  }
}

@media (max-width: 320px) {
  .loader-dot {
    width: 2px;
    height: 2px;
  }
  @keyframes loader-animation {
    0% {
      transform: rotate(0deg) translateX(4.2rem) scale(0.4);
      opacity: 0;
    }
    100% {
      transform: rotate(-360deg) translateX(4.2rem) scale(1.5);
      opacity: 1;
    }
  }
}

.loader-dot:nth-child(1) {
  animation-delay: 0s;
}
.loader-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.loader-dot:nth-child(3) {
  animation-delay: 0.4s;
}
.loader-dot:nth-child(4) {
  animation-delay: 0.6s;
}
.loader-dot:nth-child(5) {
  animation-delay: 0.8s;
}
.loader-dot:nth-child(6) {
  animation-delay: 1.0s;
}
.loader-dot:nth-child(7) {
  animation-delay: 1.2s;
}
.loader-dot:nth-child(8) {
  animation-delay: 1.4s;
}
.loader-dot:nth-child(9) {
  animation-delay: 1.6s;
}
.loader-dot:nth-child(10) {
  animation-delay: 1.8s;
}
.loader-dot:nth-child(11) {
  animation-delay: 2.0s;
}
.loader-dot:nth-child(12) {
  animation-delay: 2.2s;
}
.loader-dot:nth-child(13) {
  animation-delay: 2.4s;
}
.loader-dot:nth-child(14) {
  animation-delay: 2.6s;
}
.loader-dot:nth-child(15) {
  animation-delay: 2.8s;
}
.loader-dot:nth-child(16) {
  animation-delay: 3.0s;
}
.loader-dot:nth-child(17) {
  animation-delay: 3.2s;
}
.loader-dot:nth-child(18) {
  animation-delay: 3.4s;
}
.loader-dot:nth-child(19) {
  animation-delay: 3.6s;
}
.loader-dot:nth-child(20) {
  animation-delay: 3.8s;
}
.loader-dot:nth-child(21) {
  animation-delay: 4.0s;
}
.loader-dot:nth-child(22) {
  animation-delay: 4.2s;
}
.loader-dot:nth-child(23) {
  animation-delay: 4.4s;
}
.loader-dot:nth-child(24) {
  animation-delay: 4.6s;
}
.loader-dot:nth-child(25) {
  animation-delay: 4.8s;
}
.loader-dot:nth-child(26) {
  animation-delay: 5.0s;
}
.loader-dot:nth-child(27) {
  animation-delay: 5.2s;
}
.loader-dot:nth-child(28) {
  animation-delay: 5.4s;
}
.loader-dot:nth-child(29) {
  animation-delay: 5.6s;
}
.loader-dot:nth-child(30) {
  animation-delay: 5.8s;
}
.loader-dot:nth-child(31) {
  animation-delay: 6.0s;
}
.loader-dot:nth-child(32) {
  animation-delay: 6.2s;
}
.loader-dot:nth-child(33) {
  animation-delay: 6.4s;
}
.loader-dot:nth-child(34) {
  animation-delay: 6.6s;
}
.loader-dot:nth-child(35) {
  animation-delay: 6.8s;
}

/* Left arrow animation */
#arrowAnim {
  width: 15vw;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0; /* Position the left arrow container at the left end */
}
.arrow {
  width: 5vw;
  height: 5vw;
  border-width: 1.0vw 1.0vw 1.0vw 1.0vw;
  border-style: solid;
  border-color: black transparent transparent black;
  transform: rotate(-45deg);
}
.arrowSliding {
  position: absolute;
  animation: slide 4s linear infinite;
}
.delay1 {
  animation-delay: 1s;
}
.delay2 {
  animation-delay: 2s;
}
.delay3 {
  animation-delay: 3s;
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(15vw);
  }
  20% {
    opacity: 1;
    transform: translateX(9vw);
  }
  80% {
    opacity: 1;
    transform: translateX(-9vw);
  }
  100% {
    opacity: 0;
    transform: translateX(-15vw);
  }
}

/* Right arrow animation */
#arrowAnimright {
  width: 15vw;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0; /* Position the right arrow container at the right end */
}
.arrowright {
  width: 5vw;
  height: 5vw;
  border-width: 1.0vw 1.0vw 1.0vw 1.0vw;
  border-style: solid;
  border-color: black transparent transparent black;
  transform: rotate(135deg);
}
.arrowSlidingright {
  position: absolute;
  animation: slideRight 4s linear infinite;
}
.delay1 {
  animation-delay: 1s;
}
.delay2 {
  animation-delay: 2s;
}
.delay3 {
  animation-delay: 3s;
}
@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-15vw);
  }
  20% {
    opacity: 1;
    transform: translateX(-9vw);
  }
  80% {
    opacity: 1;
    transform: translateX(9vw);
  }
  100% {
    opacity: 0;
    transform: translateX(15vw);
  }
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  #arrowAnim {
    width: 12vw;
    height: 12vh;
  }
  .arrow {
    width: 5vw;
    height: 5vw;
    border-width: 0.8vw 0.8vw 0.8vw 0.8vw;
    transform: rotate(-45deg);
  }
  #arrowAnimright {
    width: 12vw;
    height: 12vh;
  }
  .arrowright {
    width: 5vw;
    height: 5vw;
    border-width: 0.8vw 0.8vw 0.8vw 0.8vw;
    transform: rotate(135deg);
  }
}
@media (max-width: 767px) {
  #arrowAnim {
    width: 10vw;
    height: 10vh;
  }
  .arrow {
    width: 5vw;
    height: 5vw;
    border-width: 0.7vw 0.7vw 0.7vw 0.7vw;
    transform: rotate(-45deg);
  }
  #arrowAnimright {
    width: 10vw;
    height: 10vh;
  }
  .arrowright {
    width: 5vw;
    height: 5vw;
    border-width: 0.7vw 0.7vw 0.7vw 0.7vw;
    transform: rotate(135deg);
  }
}

/* Up arrow animation */
#arrowAnimUp {
  width: 15vw;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowUp {
  width: 5vw;
  height: 5vw;
  border-style: solid;
  border-width: 1.0vw 0.5vw 0.5vw 1.0vw;
  border-color: black transparent transparent black;
  transform: rotate(45deg);
}

.arrowSlidingUp {
  position: absolute;
  animation: slideUp 4s linear infinite;
}

.delay1 {
  animation-delay: 1s;
}
.delay2 {
  animation-delay: 2s;
}
.delay3 {
  animation-delay: 3s;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(15vh);
  }
  20% {
    opacity: 1;
    transform: translateY(9vh);
  }
  80% {
    opacity: 1;
    transform: translateY(-9vh);
  }
  100% {
    opacity: 0;
    transform: translateY(-15vh);
  }
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  #arrowAnimUp {
    width: 15vw;
    height: 15vh;
  }
  .arrowUp {
    width: 5vw;
    height: 5vw;
    border-width: 1.0vw 0.5vw 0.5vw 1.0vw;
    transform: rotate(45deg);
  }
}

@media (max-width: 767px) {
  #arrowAnimUp {
    width: 15vw;
    height: 15vh;
  }
  .arrowUp {
    width: 5vw;
    height: 5vw;
    border-width: 1.0vw 0.5vw 0.5vw 1.0vw;
    transform: rotate(45deg);
  }
}

/* Down arrow animation */
#arrowAnimDown {
  width: 15vw;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowDown {
  width: 5vw;
  height: 5vw;
  border-style: solid;
  border-width: 1.0vw 0.5vw 0.5vw 1.0vw;
  border-color: black transparent transparent black;
  transform: rotate(225deg);
}

.arrowSlidingDown {
  position: absolute;
  animation: slideDown 4s linear infinite;
}

.delay1 {
  animation-delay: 1s;
}
.delay2 {
  animation-delay: 2s;
}
.delay3 {
  animation-delay: 3s;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-15vh);
  }
  20% {
    opacity: 1;
    transform: translateY(-45deg);
  }
  80% {
    opacity: 1;
    transform: translateY(9vh);
  }
  100% {
    opacity: 0;
    transform: translateY(15vh);
  }
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  #arrowAnimDown {
    width: 15vw;
    height: 15vh;
  }
  .arrowDown {
    width: 5vw;
    height: 5vw;
    border-width: 1.0vw 0.5vw 0.5vw 1.0vw;
    transform: rotate(225deg);
  }
}

@media (max-width: 767px) {
  #arrowAnimDown {
    width: 15vw;
    height: 15vh;
  }
  .arrowDown {
    width: 5vw;
    height: 5vw;
    border-width: 1.0vw 0.5vw 0.5vw 1.0vw;
    transform: rotate(225deg);
  }
}

.webCam {
  aspect-ratio: 1 / 1;
  width: 100%;
  max-width: 25rem; /* for large screens */
}

@media (max-width: 1241px) {
  .webCam {
    max-width: 18rem; /* for medium screens */
  }
}

@media (max-width: 920px) {
  .webCam {
    max-width: 15rem; /* for small screens */
  }
}
@media (max-width: 800px) {
  .webCam {
    max-width: 12rem; /* for small screens */
  }
}
